// src/sidebar/sidebar.jsx
import React, { useState, useEffect } from 'react';
import './sidebar.css';
import { fetchNowPlayingData } from '../fetch';

const Sidebar = () => {
  const [songHistory, setSongHistory] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const data = await fetchNowPlayingData();
      setSongHistory(data.history || []); // Use 'history' from the new API response
    };

    fetchData();
    const interval = setInterval(fetchData, 3000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="sidebar">
      <h3>Last 10 Songs</h3>
      <ul>
        {songHistory.length === 0 ? (
          <li className="song-item">
            <img src="default.png" alt="Default" />
            <div className="song-info">
              <p className="song-title">Unknown Title</p>
              <p className="song-artist">Unknown Artist</p>
              <p className="song-time">00:00</p>
            </div>
          </li>
        ) : (
          songHistory.map((song, index) => (
            <li className="song-item" key={index}>
              <img src={song.image || 'default.png'} alt={song.title} />
              <div className="song-info">
                <p className="song-title">{song.title || 'Unknown Title'}</p>
                <p className="song-artist">{song.author || 'Unknown Artist'}</p>
              </div>
            </li>
          ))
        )}
      </ul>
    </div>
  );
};

export default Sidebar;
