//index.js
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Player from './player/player';
import Header from './header/header.jsx'
import Sidebar from './sidebar/sidebar.jsx'
import Content from './content/content.jsx'

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Header />
    <Content />
    <Sidebar />
    <Player />
  </React.StrictMode>
);
