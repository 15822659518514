let cachedData = null;
let lastFetchedTime = 0;

export const fetchNowPlayingData = async () => {
  const currentTime = Date.now();
  if (cachedData && (currentTime - lastFetchedTime) < 10000) {
    return cachedData; // Return cached data if it's less than 3 seconds old
  }

  try {
    const response = await fetch('https://ihatecors.gglvxd.workers.dev/?q=https://radioapi.gglvxd.eu.org/api/frydefm');
    const data = await response.json();
    cachedData = data;
    lastFetchedTime = currentTime;
    return data;
  } catch (error) {
    console.error('Error fetching now playing data:', error);
    return { now_playing: { song: {} }, song_history: [] };
  }
};
