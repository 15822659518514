import React, { useState, useRef, useEffect } from 'react';
import './player.css';
import { IoMdPlayCircle } from 'react-icons/io';
import { FaStop, FaVolumeUp } from 'react-icons/fa';
import { fetchNowPlayingData } from '../fetch';

function Player() {
  const audioRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [volume, setVolume] = useState(1);
  const [nowPlaying, setNowPlaying] = useState({ title: 'Unknown Title', author: 'Unknown Artist', image: 'https://via.placeholder.com/100' });

  const togglePlayPause = () => {
    if (isPlaying) {
      audioRef.current.pause();
    } else {
      audioRef.current.play().catch((error) => {
        console.error("Error playing audio:", error);
      });
    }
    setIsPlaying(!isPlaying);
  };

  const handleVolumeChange = (e) => {
    const newVolume = e.target.value;
    setVolume(newVolume);
    audioRef.current.volume = newVolume;
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchNowPlayingData();
        const { title, author, image } = data; // Adjust based on actual API response
        setNowPlaying({ title: title || 'Unknown Title', author: author || 'Unknown Artist', image: image || 'https://via.placeholder.com/100' });
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
    const interval = setInterval(fetchData, 3000);
    
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="App">
      <div className="player">
        <div className="album-art">
          <img src={nowPlaying.image} alt="Album Art" />
          <div className="song-info">
            <div className="song-title">{nowPlaying.title}</div>
            <div className="song-artist">{nowPlaying.author}</div>
          </div>
        </div>
        <div className="play-pause-container">
          <button className="play-pause" onClick={togglePlayPause}>
            {isPlaying ? <FaStop size={30} /> : <IoMdPlayCircle size={30} />}
          </button>
        </div>
        <div className="volume-control">
          <input
            type="range"
            min="0"
            max="1"
            step="0.01"
            value={volume}
            onChange={handleVolumeChange}
            className="volume-slider"
          />
          <FaVolumeUp size={20} className="volume-icon" />
        </div>
        <audio ref={audioRef} src="https://listen.gglvxd.eu.org/listen/frydefm/radio.aac" />
      </div>
    </div>
  );
}

export default Player;
