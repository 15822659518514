// src/content/content.jsx

import React from "react";
import "./content.css";
import paypal from './paypal.png';

const Content = () => {
  return (
    <div className="content-container">
      <section className="contact-info">
        <h2>Contact Information</h2>
        <h2>Email: <a href="mailto:frydefm@gmail.com">frydefm@gmail.com</a></h2>
        <h2>Discord Server: <a href="https://discord.gg/2t4n9WptdJ">https://discord.gg/2t4n9WptdJ</a></h2>
        <h2>Telegram: <a href="https://t.me/GGLVXD">@GGLVXD</a></h2>
      </section>

      <section className="advertise">
        <h2>Advertise on FrydeFM</h2>
        <p>
          Reach a broad audience with your message on FrydeFM, a 24/7 pop music radio station!
        </p>
        <h3>Advertisement Details:</h3>
        <ul>
          <li>Each week your ad will be played 24 times a day, every hour</li>
          <li>The ad should be maximum 30 seconds long. A cover is recommended but not required.</li>
          <li>The cost is €1 per week.</li>
        </ul>
        <h3>Contact Information:</h3>
        <p>
          To book your ad slot or for more information, please contact us at: 
          <a href="mailto:frydefm@gmail.com"> frydefm@gmail.com</a>
        </p>
        <h3>Payment Method:</h3>
        <img src={paypal} alt="paypal" />
        <p>Payments are non-refundable. </p>
      </section>

      <section className="discord-embed">
        <h2><a href="https://discord.gg/XjMuX7UPRr">Join Our Discord Server</a></h2>
        <iframe
          src="https://discord.com/widget?id=1255429351088984147&theme=dark"
          width="100%"
          height="500"
          allowTransparency="true"
          frameBorder="0"
          sandbox="allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts"
          title="Discord Server"
        ></iframe>
        <p><a href="https://discord.gg/XjMuX7UPRr"> https://discord.gg/XjMuX7UPRr</a></p>
      </section>
    </div>
  );
};

export default Content;
